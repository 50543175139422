import React, { useState } from 'react'
import ProductSide from './ProductSide'
import ProductItem from './ProductItem'
import ReactPaginate from 'react-paginate'
import { useSelector, useDispatch } from 'react-redux'
// import { getProductContent } from '@services/api.service'
import { setFilterPage, setGotoElement, setCurrentProduct } from '@reducers/contentReducer'
import helper from '@utils/helper'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
// import { useMediaQuery } from 'react-responsive'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import Navigation from '@components/Navigation'
import { Link } from 'react-router-dom'
import noDataText from '@data/NoData'

export default function ProductList({
    user,
    pageTitle,
    seoData,
    vehicleText
}) {
    const dispatch = useDispatch()
    const productsList = useSelector((state) => state.content.productsList)
    const productsFilter = useSelector((state) => state.content.productsFilter)
    const searchByVehicle = useSelector((state) => state.content.searchByVehicle)
    const handlePageClick = (e) => {
        const selected = e.selected
        dispatch(setFilterPage(selected + 1))
        dispatch(setGotoElement('product-list'))
    }
    const isMobile = useSelector((state) => state.content.isMobile)
    // const isMobile = useMediaQuery({ query: '(max-width: 630px' })
    const [isShowSide, setIsShowSide] = useState(!isMobile)

    const handleClickProduct = async (id) => {
        // try {
        //     const result = await getProductContent(id, { username: user?.username || '', token: user?.token || '' })
        //     console.log('result', result?.data)
        //     dispatch(setCurrentProduct(result?.data))
        // } catch (err) {
        //     console.log(err.data?.message || 'get product content error')
        // }
    }

    console.log('productsList seoData:', seoData)

    return (
        <>
            <Navigation
                title='Tires'
                titleLink='/tires'
                subtitle={seoData?.page ? seoData?.page : (seoData?.h1 || 'Tires')}
                // subtitleLink={'#'}
                imageUrl='/images/banner-products.webp'
                pageType='product_list'
                vehicleText={vehicleText}
            />
            <SeoTitleAndDesc
                title={pageTitle}
                seo_title={seoData?.title}
                seo_desc={seoData?.desc}
            />
            <div className='mobile-filter' onClick={() => {
                setIsShowSide(!isShowSide)
            }}>
                <h3>FILTER</h3>
                {isShowSide ?
                    <IoIosArrowUp size={20} />
                    : <IoIosArrowDown size={20} />}
            </div>
            {isMobile && searchByVehicle?.size && (
                <div className='w-full p-5'>
                    <div className='flex gap-3'>
                        <h1 className="text-[30px]">
                            Search by vehicle
                        </h1>
                        <Link to={`/search-by-vehicle`} className='bg-[#B33030] text-white px-3 rounded flex items-center hover:text-white'>
                            Edit
                        </Link>
                    </div>
                    <p className="text-[20px]">
                        {Object.entries(searchByVehicle).map(([key, value], index) => (
                            key !== 'size' &&
                            <span key={index}>
                                {value}
                                {key !== 'name' && <span className="text-[#e3e3e3]"> / </span>}
                            </span>
                        ))}
                    </p>
                </div>
            )}
            <div className="product-content">
                {isShowSide &&
                    <ProductSide />
                }
                <div className="product-list" id='product-list'>
                    {!isMobile && searchByVehicle?.size && (
                        <div >
                            <div className='flex gap-3'>
                                <h1 className="text-[30px]">
                                    Search by vehicle
                                </h1>
                                <Link to={`/search-by-vehicle`} className='bg-[#B33030] text-white px-3 rounded flex items-center hover:text-white'>
                                    Edit
                                </Link>
                            </div>
                            <p className="text-[20px]">
                                {/* {Object.values(searchByVehicle)
                                    .map((item, index, arr) => (
                                        <span key={index}>
                                            {item}
                                            {index < arr.length - 1 && <span className="text-[#e3e3e3]"> / </span>}
                                        </span>
                                    ))}
                                <br /> */}
                                {Object.entries(searchByVehicle).map(([key, value], index) => (
                                    key !== 'size' &&
                                    <span key={index}>
                                        {value}
                                        {key !== 'name' && <span className="text-[#e3e3e3]"> / </span>}
                                    </span>
                                ))}
                            </p>
                        </div>
                    )}
                    <div className="column-header">
                        <h1>{seoData?.page ? seoData?.page : (seoData?.h1 || 'Tires')}</h1>
                        <span className="header-total">Total {productsList?.total} products found</span>
                    </div>
                    <div className="product-grid">
                        {productsList.data && productsList.data.map((item, index) => (
                            <ProductItem
                                key={index}
                                product={item}
                                link={`/tires/detail/${item.id}/${helper.formatTitleForLink(item.title)}`}
                                onClick={() => handleClickProduct(item.id)}
                            />
                        ))}
                        {productsList.total === 0 &&
                            <div className="no-data">
                                <h2>No Data Found</h2>
                                <br />
                                <p dangerouslySetInnerHTML={{ __html: noDataText }} />
                            </div>
                        }
                    </div>
                    {productsList.totalPage > 0 && (
                        <div className="pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={4}
                                pageCount={productsList.totalPage}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                forcePage={productsFilter.current - 1}
                            // onClick={handleGotoElement}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
