import './Home.css'
import React, { Suspense, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import HeroSection from './components/HeroSection'
import CommentSection from './components/CommentSection'
import EasyToBuy from './components/EasyToBuy'
import { useSelector } from 'react-redux'
import ProductSection from '@components/ProductSection'
import { Link } from 'react-router-dom'
import MyImage from '../../components/MyImage'
import { Drawer } from 'antd'
import { usePWAInstall } from 'react-use-pwa-install'
import HeroSectionMobile from './components/HeroSectionMobile'
import SearchByVehicle from './components/SearchByVehicle'
import SearchByVehicleMobile from './components/SearchByVehicleMobile'
// import { Carousel } from 'antd'

export default function Home() {
  const install = usePWAInstall()
  const [isOpen, setIsOpen] = React.useState(false)
  // console.log('Home.js', install)
  const typeList = useSelector((state) => state.content.productsTypeList) || []
  const isMobile = useSelector((state) => state.content.isMobile)
  const productsTypeList = typeList.filter(
    (item) => item.is_wheel === 0 && item.title !== 'Tire Bags'
  )

  useEffect(() => {
    if (install) setIsOpen(true)
  }, [install])

  const schema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'AutomotiveBusiness',
        name: 'TireMaxx',
        url: 'https://www.tiremaxx.ca',
        logo: 'https://www.tiremaxx.ca/images/logo.webp',
        image: 'https://www.tiremaxx.ca/images/logo.webp',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Bay 12, 91 Skyline Crescent NE',
          addressLocality: 'Calgary',
          addressRegion: 'AB',
          postalCode: 'T2K 5X2',
          addressCountry: 'CA'
        },
        priceRange: '$$',
        telephone: '+1-368-999-9988',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+1-368-999-9988',
          contactType: 'customer service',
          areaServed: 'CA',
          availableLanguage: ['English', 'French']
        },
        sameAs: [
          'https://www.facebook.com/tiremaxxyyc',
          'https://www.instagram.com/tiremaxxyyc',
          'https://twitter.com/tiremaxxyyc',
          'https://www.tiktok.com/@tiremaxxyyc'
        ]
      },
      {
        '@type': 'LocalBusiness',
        name: 'Tiremaxx Tire Shop in Calgary',
        image: 'https://www.tiremaxx.ca/images/logo.webp',
        telephone: '+1-368-999-9988',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Bay 12, 91 Skyline Crescent NE',
          addressLocality: 'Calgary',
          addressRegion: 'AB',
          postalCode: 'T2K 5X2',
          addressCountry: 'CA'
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: 51.097778,
          longitude: -114.045
        },
        openingHours: 'Mo-Fr 09:00-17:00, Sa 10:00-15:00',
        url: 'https://www.tiremaxx.ca',
        priceRange: '$$'
      }
    ]
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            Search By Vehicle | TireMaxx - Your Trusted Tire Shop in Canada
          </title>
          <meta
            property="og:title"
            content={'Search By Vehicle | TireMaxx - Your Trusted Tire Shop in Canada'}
          />
          <meta
            property="og:description"
            content={
              'Find the best tires for your car, truck, or SUV at TireMaxx. Affordable prices and fast shipping.'
            }
          />
          <meta
            property="og:image"
            content={'https://tiremaxx.ca/images/hero5.webp'}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content={'website'} />
          <meta property="og:locale" content="en_CA" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={'TireMaxx - Your Trusted Tire Shop in Canada'}
          />
          <meta
            name="twitter:description"
            content={
              'Find the best tires for your car, truck, or SUV at TireMaxx. Affordable prices and fast shipping.'
            }
          />
          <meta
            name="twitter:image"
            content={'https://tiremaxx.ca/images/hero5.webp'}
          />
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
      </HelmetProvider>

      <div className='w-full min-h-[800px] flex items-center'
        style={{
          backgroundImage: `url(/images/hero2.webp)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
        }}
      >
        {isMobile ? <SearchByVehicleMobile /> :
          <div className='w-[1280px] smsize:w-full m-auto flex items-center justify-between gap-5'>
            {/* <div className='text-white p-5 shadow-lg bg-black bg-opacity-50 rounded-lg'>
            <h3 className='text-[45px]'>Find the best tires for your vehicle</h3>
            <p className='text-[30px]'>Search by vehicle to find the best tires for your car, truck, or SUV</p>
          </div> */}
            <SearchByVehicle />
          </div>
        }
      </div>
    </>
  )
}
