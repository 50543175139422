const helper = {}

helper.replaceSpaceWithDash = (str) => {
    return str.toLowerCase().replace(/\s/g, '-')
}

helper.formatTitleForLink = (title) => {
    if (!title) return ''
    return title
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "")
}

helper.formatDate = (timestamp) => {
    if (!timestamp) return ''
    // return date.toLocaleDateString('en-US', {
    //     month: '2-digit',
    //     day: '2-digit',
    //     year: 'numeric'
    // })
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

helper.generateUUID = () => {
    // Generate a random string in the format of a UUID version 4
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
        const randomHex = Math.random() * 16 | 0
        const value = char === 'x' ? randomHex : (randomHex & 0x3 | 0x8)
        return value.toString(16)
    })
}

helper.isYearValid = (year) => {
    year = parseInt(year)
    return year >= 1970 && year <= 2030
}

helper.capitalizeFirstWord = (str) => {
    return str.replace(/^\w+/, word => word.charAt(0).toUpperCase() + word.slice(1))
}


export default helper