import React, { useState, useEffect } from 'react'
import { searchByVehicle } from 'services/api.service'
import YearGrid from './YearGrid'
import MakeGrid from './MakeGrid'
import ModelGrid from './ModelGrid'
import SubModelGrid from './SubModelGrid'
import OptionGrid from './OptionGrid'
import SizeGrid from './SizeGrid'
import { setSearchByVehicle } from '@reducers/contentReducer'
import { useDispatch, useSelector } from 'react-redux'
import VehicleGrid from './VehicleGrid'

const TitleList = ['Year', 'Make', 'Model', 'Option', 'Size']
const TitleValueList = ['', 'year', 'make', 'model', 'option', 'size']

const TitleHeader = ({ title, value, type, selected, setSelected }) => {
    const index = TitleValueList.findIndex((item) => item === value)
    let clickValues = selected
    for (let i = index + 1; i < TitleValueList.length; i++) {
        clickValues = {
            ...clickValues,
            [TitleValueList[i]]: null,
        }
    }
    let color = '#000000'


    if (type === value) {
        // console.log('index1111', TitleList.slice(0, index + 1))
        // if (TitleList.slice(0, index + 1).includes(value)) {
        //     color = '#000000'
        // }
        color = '#ff0000'
    }
    console.log('clickValues', clickValues)
    return (
        <div
            className={`cursor-pointer font-bold flex items-center gap-2`}

            onClick={() => {
                setSelected(clickValues)
            }}>
            <h3 style={{ color: color }}>{title}</h3>
            {title !== 'Size' && <NextArrow />}
        </div>
    )
}
const NextArrow = () => {
    return <div className='font-light text-[16px] flex items-center justify-center'> &gt; </div>
}

export default function SearchByVehicle() {
    const [type, setType] = useState('')
    const [data, setData] = useState([])
    // const [years, setYears] = useState([])
    // const [makes, setMakes] = useState([])
    // const [models, setModels] = useState([])
    // const [subModels, setSubModels] = useState([])
    // const [options, setOptions] = useState([])
    // const [sizes, setSizes] = useState([])
    // const [selected, setSelected] = useState({
    //     year: null,
    //     make: null,
    //     model: null,
    //     subModel: null,
    //     option: null,
    //     size: null,
    // })

    const dispatch = useDispatch()
    const setSelected = (value) => {
        dispatch(setSearchByVehicle(value))
    }
    const selected = useSelector((state) => state.content.searchByVehicle)
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('selected', selected)
                const res = await searchByVehicle(selected)
                console.log('fetchData', res)
                setType(res.type)
                setData(res.data)
            } catch (error) {
                console.log('fetchData', error)
            }
        }
        fetchData()
    }, [selected])

    return (
        <div className='p-10 smsize:p-3 w-[800px] m-auto shadow-lg bg-white smsize:w-full'>
            <div className='flex gap-2'>
                {TitleList.map((item, index) => (
                    <TitleHeader index={index} title={item} value={TitleValueList[index]} type={type} selected={selected} setSelected={setSelected} />
                ))}
                {/* <TitleHeader title='Year' value='' type={type} selected={selected} setSelected={setSelected} />
                <NextArrow />
                <TitleHeader title='Make' value='year' type={type} selected={selected} setSelected={setSelected} />
                <NextArrow />
                <TitleHeader title='Model' value='make' type={type} selected={selected} setSelected={setSelected} />
                <NextArrow />
                <TitleHeader title='Sub Model' value='model' type={type} selected={selected} setSelected={setSelected} />
                <NextArrow />
                <TitleHeader title='Option' value='subModel' type={type} selected={selected} setSelected={setSelected} />
                <NextArrow />
                <TitleHeader title='Size' value='option' type={type} selected={selected} setSelected={setSelected} /> */}
            </div>
            <VehicleGrid type={type} list={data} setSelected={setSelected} selected={selected} />
            {/* {type === '' && (
                <YearGrid years={years} setSelected={setSelected} selected={selected} isMobile={isMobile} />
            )}
            {type === 'year' && (
                <MakeGrid makes={makes} setSelected={setSelected} selected={selected} isMobile={isMobile} />
            )}
            {type === 'make' && (
                <ModelGrid models={models} setSelected={setSelected} selected={selected} isMobile={isMobile} />
            )}
            {type === 'model' && (
                <SubModelGrid subModels={subModels} setSelected={setSelected} selected={selected} isMobile={isMobile} />
            )}
            {type === 'subModel' && (
                <OptionGrid options={options} setSelected={setSelected} selected={selected} isMobile={isMobile} />
            )}
            {type === 'option' && (
                <SizeGrid sizes={sizes} setSelected={setSelected} selected={selected} isMobile={isMobile} />
            )} */}
        </div>
    )
}
