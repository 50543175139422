import React from 'react'
import './CommentSection.css'
import { config } from '@config'
import { AiFillStar } from 'react-icons/ai'

const comments = [
    {
        id: 1,
        name: 'Ileanna Abad',
        star: 5,
        date: '3 weeks ago',
        comment: 'I recently visited Tiremaxx to get some tires and had gotten them installed here as well. Definitely convenient,  efficient, and so cheap!!',
        image: '/images/footer-facebook.webp',
        link: config.google_review
    },
    {
        id: 2,
        name: 'Kathleen Yang',
        star: 5,
        date: '3 weeks ago',
        comment: 'I love the tires here with great quality service. The license mechanical has more then 30 years Canadian working experience. After repaired my old car, I can feel like I am driving a new car now.',
        image: '/images/footer-instagram.webp',
        link: config.google_review
    },
    {
        id: 3,
        name: 'Juliana Delos Santos',
        star: 5,
        date: '3 weeks ago',
        comment: 'Got my winter tires from Tiremaxx and got them installed at the same time! Affordable, quick and reliable!! So convenient!!',
        image: '/images/footer-twitter.webp',
        link: config.google_review
    }
]

export default function CommentSection() {
    return (
        <div className='comment-wrap'>
            <h3>Customer reviews from Google</h3>
            <a href={config.google_review} alt={'write a review'} target="_blank" rel="noindex nofollow noopener noreferrer">Write a review</a>
            <div className='comment-section'>
                {comments.map(item => (
                    <div className='comment-item' key={item.id}>
                        {/* <img width={60} src={item.image} alt={item.name} /> */}
                        <h3>{item.name}</h3>
                        <div className='flex gap-2 items-center justify-start'>
                            <span className='flex' style={{ color: '#fbbc04' }}>{Array(item.star).fill().map((_, i) => <AiFillStar key={i} />)}</span>
                            {item.date}
                        </div>
                        <p>{item.comment}</p>
                        <a href={item.link} alt={item.name} target="_blank" rel="noindex nofollow noopener noreferrer">Read More</a>
                    </div>
                ))}
            </div>
        </div>
    )
}
