import React, { useState, useEffect } from 'react'
import { searchByVehicle } from 'services/api.service'
import { setSearchByVehicle } from '@reducers/contentReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const formatSize = (size) => {
    let sizeStr = size.replace('/', '')
    sizeStr = sizeStr.replace(' ', '')
    return sizeStr.toLowerCase()
}

const TitleList = ['Year', 'Make', 'Model', 'Option', 'Size']
const TitleValueList = ['', 'year', 'make', 'model', 'option', 'size']

const TitleHeader = ({ title, value, type, selected, setSelected, children }) => {
    const index = TitleValueList.findIndex((item) => item === value)
    let clickValues = selected
    for (let i = index + 1; i < TitleValueList.length; i++) {
        clickValues = {
            ...clickValues,
            [TitleValueList[i]]: null,
        }
    }
    let color = '#000000'
    if (type === value) {
        color = '#ff0000'
    }
    return (
        <div id={TitleValueList[index + 1]} className='w-full flex flex-col gap-2 items-center justify-center'>
            <div
                className={`w-full cursor-pointer pt-1 pb-3 font-bold flex justify-center items-center border-b border-gray-200`}
                onClick={() => {
                    setSelected(clickValues)
                }}
                style={{
                    color: color,
                    borderBottom: title === 'Size' ? 'none' : ''
                }}
            >
                {title}
            </div>
            <div className='w-full flex flex-col gap-2 items-start justify-start max-h-[1024px] overflow-y-auto'>
                {children}
            </div>
        </div>
    )
}

export default function SearchByVehicleMobile() {
    const [type, setType] = useState('')
    const [data, setData] = useState([])
    const [showHtmls, setShowHtmls] = useState({
        // "Year": '',
        // "Make": '',
        // "Model": '',
        // "Sub Model": '',
        // "Option": '',
        // "Size": ''
    })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const setSelected = (value) => {
        dispatch(setSearchByVehicle(value))
    }
    const selected = useSelector((state) => state.content.searchByVehicle)

    const scrollToElement = (id) => {
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',  // 平滑滚动
                block: 'center',     // 滚动到元素中心
            })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('selected', selected)
                const res = await searchByVehicle(selected)
                console.log('fetchData', res)
                setType(res.type)
                setData(res.data)
                let html = []
                const findIndex = TitleValueList.findIndex((item) => item === res.type)

                for (const item of res.data) {
                    html.push(
                        <button
                            key={item.name}
                            className='w-full items-center justify-center hover:bg-gray-200 cursor-pointer p-1'
                            onClick={() => {
                                setSelected({
                                    ...selected,
                                    [TitleValueList[findIndex + 1]]: item.value || item.name,
                                    name: item.name
                                })
                                // 延迟滚动，确保元素已经渲染
                                setTimeout(() => {
                                    scrollToElement(TitleValueList[findIndex + 1])
                                }, 100)  // 延迟 100ms
                                if (TitleValueList[findIndex + 1] === 'size') {
                                    let link = `/tires/${selected?.make}/${selected?.model}/${selected?.year}/${item?.value[0]?.replace('/', '-')}`
                                    navigate(link?.toLowerCase())
                                }
                            }}
                        >
                            {item.name}
                        </button>
                    )
                }
                // 更新对应的 HTML 片段
                setShowHtmls({
                    [res.type]: html
                })

            } catch (error) {
                console.log('fetchData', error)
            }
        }
        fetchData()
    }, [selected])

    return (
        <div className='m-5 flex flex-col gap-5 items-start justify-start'>
            <div className='flex flex-col p-3 items-center justify-center text-white text-center'>
                <h3 className='text-[40px] shadow-xl'>Shop Tires By Vehicle</h3>
                <p className='text-[20px]'>The best way to enjoy winter is to be ready for it.</p>
            </div>
            <div className='p-5 shadow-lg bg-white w-full rounded-2xl border border-gray-200'>
                <div className='w-full flex flex-col gap-2 items-center justify-center'>
                    {TitleList.map((item, index) => (
                        <TitleHeader
                            key={index}
                            index={index}
                            title={item}
                            value={TitleValueList[index]}
                            type={type}
                            selected={selected}
                            setSelected={setSelected}
                        >
                            {showHtmls[TitleValueList[index]]}
                        </TitleHeader>
                    ))}
                    {/* <div className='w-full flex flex-col gap-2 items-center justify-center'>
                    {type === '' && (
                        years.map((item, index) => {
                            return (
                                <button className='w-full items-center justify-center hover:bg-gray-200 cursor-pointer p-1' key={index}>{item.year}</button>
                            )
                        })
                    )}
                </div> */}
                </div>
            </div>
        </div>
    )
}
