import React, { useState, useEffect } from 'react'
import ProductView from './components/ProductView'
import PolicyModal from './components/PolicyModal'
import './Product.css'
// import { useQuery } from '@tanstack/react-query'
import { getProductContent } from '@services/api.service'
import { useParams } from 'react-router-dom'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import Navigation from '@components/Navigation'
import { config } from '@config'
import { ContentCode } from '@data/ContentData'
import ContentDataHook from '@hooks/ContentData.hook'
import helper from 'utils/helper'
import NotFound from 'components/NotFound'

export default function ProductDetail({
  // policy,
  // delivery,
  user,
  // currentRow,
  productsTypeList,
  pathname
}) {
  const [show, setShow] = useState(false)
  const { id } = useParams()
  const [isRefresh, setIsRefresh] = useState(false)
  const [product, setProduct] = useState(null)
  const [isNotFound, setIsNotFound] = useState(false)

  const { data: policy } = ContentDataHook(ContentCode.STORE)
  const { data: delivery } = ContentDataHook(ContentCode.DELIVERY)

  // const {
  //     isPending,
  //     error,
  //     data,
  // } = useQuery({
  //     queryKey: ['getProduct', id, isRefresh, user, pathname],
  //     queryFn: async () => {
  //         console.log('getProduct id', id)
  //         const res = await getProductContent(id, { username: user?.username || '', token: user?.token || '', pathname: pathname })
  //         return res
  //     }
  // })

  // if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
  // if (error) return 'An error has occurred: ' + error.message

  useEffect(() => {
    async function getProduct() {
      try {
        const res = await getProductContent(id, {
          username: user?.username || '',
          token: user?.token || '',
          pathname: pathname
        })
        setProduct(res?.data)
        return res
      } catch (error) {
        if (error?.data?.message === 'product not found') {
          setIsNotFound(true)
        }
        console.error('getProduct error', error)
      }
    }

    getProduct()
  }, [id, isRefresh, user, pathname])


  if (isNotFound) {
    return <NotFound />
  }

  // const product = data?.data
  console.log('product', product)

  if (!product) {
    return <div style={{ textAlign: 'center' }}>Loading...</div>
  }
  const typeName = helper.formatTitleForLink(product?.type_name)
  const typeContent = productsTypeList?.find(
    (item) => item.id === product?.type_id
  )
  let imageUrl = product?.pattern_picture
  if (product?.pictures) {
    imageUrl = product?.pictures.split(',')[0]
  }
  return (
    <>
      <SeoTitleAndDesc
        title={`${product?.title} | ${config.appName}`}
        seo_title={product?.seo_title}
        seo_desc={product?.seo_desc}
      />
      <Navigation
        title="Tires"
        titleLink="/tires"
        subtitle={product?.type_name}
        subtitleLink={`/tires/list/${typeName}`}
        lasttitle={product?.title}
        imageUrl={imageUrl}
        pageType="product_detail"
      />
      <PolicyModal show={show} setShow={setShow} policy={policy} />
      <ProductView
        user={user}
        currentRow={product}
        setShow={setShow}
        setIsRefresh={setIsRefresh}
      />
      {typeContent && (
        <div className="delivery-item">
          <p
            className="desc-content ql-editor"
            dangerouslySetInnerHTML={{ __html: typeContent?.description }}
          ></p>
        </div>
      )}
      {delivery && (
        <div className="delivery-item">
          <p
            className="desc-content ql-editor"
            dangerouslySetInnerHTML={{ __html: delivery.content }}
          ></p>
        </div>
      )}
    </>
  )
}
