/**
 * contentReducer.ts, it handles content actions, including init content list
 * @author Dean Yang
 * @version Jan 1, 2023
 */
import { createSlice } from '@reduxjs/toolkit'

const initialProductsFilter = {
    current: 1,
    pageSize: 10,
    title: '',
    types: [],
    status_type: 0,
    brands: [],
    width: 0,
    profile: 0,
    rim: 0
}

const initialSearchByVehicle = {
    year: null,
    make: null,
    model: null,
    // subModel: null,
    option: null,
    size: null
}

export const initialState = {
    isMobile: false,
    gotoElement: null,
    currentNewsId: null,
    currentNews: null,
    currentProduct: null,
    contents: [],
    questions: [],
    newsTypeList: [],
    productsTypeList: [],
    productsFilter: initialProductsFilter,
    newsFilter: {
        current: 1, pageSize: 20, types: []
    },
    productsList: {
        data: [],
        status: 'success',
        total: 0,
        current: 1,
        pageSize: 10,
        totalPage: 1
    },
    newsList: {
        data: [],
        status: 'success',
        total: 0,
        current: 1,
        pageSize: 20,
        totalPage: 1
    },
    searchByVehicle: initialSearchByVehicle
}

const contentSlice = createSlice({
    name: 'content',
    initialState: initialState,
    reducers: {
        setCurrentNewsId: (state, action) => {
            state.currentNewsId = action.payload
        },
        setCurrentNews: (state, action) => {
            state.currentNews = action.payload
        },
        setCurrentProduct: (state, action) => {
            state.currentProduct = action.payload
        },
        setGotoElement: (state, action) => {
            state.gotoElement = action.payload
        },
        initContentList: (state, action) => {
            state.contents = action.payload
        },
        initQuestionList: (state, action) => {
            state.questions = action.payload
        },
        initNewsTypeList: (state, action) => {
            state.newsTypeList = action.payload
        },
        initProductsTypeList: (state, action) => {
            state.productsTypeList = action.payload
        },
        setProductsList: (state, action) => {
            state.productsList = action.payload
        },
        setNewsList: (state, action) => {
            state.newsList = action.payload
        },
        setSearchTitle: (state, action) => {
            state.productsFilter.title = action.payload
        },
        setStatusType: (state, action) => {
            state.productsFilter.status_type = action.payload
        },
        setProductsFilter: (state, action) => {
            state.productsFilter = {
                current: 1, pageSize: 10, status_type: 0,
                title: action.payload.title,
                types: action.payload.types,
                brands: action.payload.brands,
                width: action.payload.width || state.productsFilter.width,
                profile: action.payload.profile || state.productsFilter.profile,
                rim: action.payload.rim || state.productsFilter.rim,
            }
        },
        setProductsFilterSize: (state, action) => {
            state.productsFilter.width = action.payload.width || state.productsFilter.width
            state.productsFilter.profile = action.payload.profile || state.productsFilter.profile
            state.productsFilter.rim = action.payload.rim || state.productsFilter.rim
        },
        initProductsFilter: (state) => {
            state.currentProduct = null
            state.productsFilter = initialProductsFilter
        },
        initNewsFilter: (state) => {
            state.currentNews = null
            state.newsFilter = { current: 1, pageSize: 20, types: [] }
        },
        setNewsFilterTypes: (state, action) => {
            if (action.payload.action === 'set') {
                // state.newsFilter.types = [action.payload.type]
                state.currentNews = null
                state.newsFilter = { current: 1, pageSize: 20, types: [action.payload.type] }
            } else if (action.payload.action === 'add') {
                state.newsFilter.types.push(action.payload.type)
            } else if (action.payload.action === 'remove') {
                state.newsFilter.types = state.newsFilter.types.filter((item) => item !== action.payload.type)
            } else if (action.payload.action === 'all') {
                // state.newsFilter.types = []
                state.currentNews = null
                state.newsFilter = { current: 1, pageSize: 20, types: [] }
            }
        },
        setFilterPage: (state, action) => {
            state.productsFilter.current = action.payload
        },
        setNewsFilterPage: (state, action) => {
            state.newsFilter.current = action.payload
        },
        setFilterTypes: (state, action) => {
            if (action.payload.action === 'set') {
                state.currentProduct = null
                state.productsFilter = { current: 1, pageSize: 10, title: '', types: [action.payload.type], status_type: 0, brands: [] }
                // state.productsFilter.types = [action.payload.type]
            } else if (action.payload.action === 'add') {
                state.productsFilter.types.push(action.payload.type)
            } else if (action.payload.action === 'remove') {
                state.productsFilter.types = state.productsFilter.types.filter((item) => item !== action.payload.type)
            } else if (action.payload.action === 'all') {
                state.productsFilter.types = []
            }
        },
        setFilterBrands: (state, action) => {
            if (action.payload.action === 'set') {
                // state.productsFilter.brands = [action.payload.brand]
                state.currentProduct = null
                state.productsFilter = { current: 1, pageSize: 10, title: '', types: [], status_type: 0, brands: [action.payload.brand] }
            } else if (action.payload.action === 'add') {
                state.productsFilter.brands.push(action.payload.brand)
            } else if (action.payload.action === 'remove') {
                state.productsFilter.brands = state.productsFilter.brands.filter((item) => item !== action.payload.brand)
            } else if (action.payload.action === 'all') {
                state.productsFilter.brands = []
            }
        },
        setIsMobile: (state, action) => {
            state.isMobile = action.payload
        },
        setSearchByVehicle: (state, action) => {
            state.searchByVehicle = action.payload
        },
    }
})

export const { setNewsFilterPage, setCurrentNewsId, setCurrentNews, setCurrentProduct, setGotoElement, initContentList, initQuestionList, initNewsTypeList, initProductsTypeList, initProductsFilter,
    initNewsFilter, setNewsFilterTypes, setNewsList, setProductsList, setSearchTitle,
    setStatusType, setFilterTypes, setFilterBrands, setFilterPage, setProductsFilter, setProductsFilterSize, setIsMobile, setSearchByVehicle } = contentSlice.actions

export default contentSlice.reducer