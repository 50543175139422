const noDataText = `
Didn't Find the Right Size?<br/><br/>
It's okay if the exact tire size for your vehicle isn't available! In many cases, we can recommend alternate tire sizes that will safely and effectively fit your vehicle. These alternate sizes are often referred to as "plus" or "minus" sizes and are carefully selected to maintain your vehicle's performance, safety, and functionality.
<br/><br/>
Using an alternate size doesn't mean compromising safety or quality. Our team ensures that the tires fit within your vehicle's specifications, including diameter, load rating, and speed rating, so you can drive with confidence.
<br/><br/>
If you're unsure or have questions, we're here to help! Reach out to us:
<br/><br/>
Call or Text: <a href='tel:(368) 999-9988' style='color: #B33030;text-decoration: underline'>(368) 999-9988</a><br/>
Email: <a href='mailto:marketing@tiremaxx.ca' style='color: #B33030; text-decoration: underline'>marketing@tiremaxx.ca</a><br/>
Visit Us: <span style='color: #B33030; text-decoration: underline'>Bay 12, 91 Skyline Crescent NE, Calgary, AB T2K 5X2</span><br/>
<br/>
Let us guide you to the perfect fit for your vehicle!
`

export default noDataText