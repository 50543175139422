import React, { useState } from 'react'
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr"
import { useNavigate } from 'react-router-dom'
const TitleValueList = ['', 'year', 'make', 'model', 'option', 'size']

export default function VehicleGrid({
    list = [],
    type,
    setSelected,
    selected,
}) {
    const [currentPage, setCurrentPage] = useState(1)
    const navigate = useNavigate()

    if (!list || list.length === 0) {
        return null
    }

    const itemsPerColumn = 14
    const columnsPerPage = 4  // 每页显示的列数
    const numColumns = Math.ceil(list.length / itemsPerColumn)  // 总列数
    const totalPages = Math.ceil(numColumns / columnsPerPage)  // 计算总页数

    // 将年份数据重新分组成列
    const columns = []
    for (let i = 0; i < numColumns; i++) {
        const columnData = list.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
        columns.push(columnData)
    }

    // 计算当前页显示的列
    const startColumnIndex = (currentPage - 1) * columnsPerPage
    const endColumnIndex = startColumnIndex + columnsPerPage
    const columnsToDisplay = columns.slice(startColumnIndex, endColumnIndex)

    const findIndex = TitleValueList.findIndex((item) => item === type)

    return (
        <div className="min-h-[400px] w-full flex flex-col gap-3">
            <div className="w-full grid grid-flow-col gap-8 pt-3">
                {columnsToDisplay.map((column, columnIndex) => (
                    <div key={startColumnIndex + columnIndex} className="flex flex-col">
                        {column.map((item, itemIndex) => (
                            <div
                                onClick={() => {
                                    setSelected({
                                        ...selected,
                                        [TitleValueList[findIndex + 1]]: item.value || item.name,
                                        name: item.name,
                                    })
                                    if (TitleValueList[findIndex + 1] === 'size') {
                                        let link = `/tires/${selected?.make}/${selected?.model}/${selected?.year}/${item?.value[0]?.replace('/', '-')}`
                                        navigate(link?.toLowerCase())
                                    }
                                }}
                                key={`${startColumnIndex + columnIndex}-${itemIndex}`}
                                className="hover:bg-gray-100 hover:text-red-500 px-3 py-1 rounded cursor-pointer text-left whitespace-nowrap"
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {totalPages > 1 && (
                <div className="w-full flex justify-end">
                    <div className="flex space-x-2">
                        <button
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                            className={`px-3 py-1 rounded cursor-pointer ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {/* Previous */}
                            <GrFormPreviousLink size={24} />
                        </button>
                        {/* <span className="px-3 py-1">{`Page ${currentPage} of ${totalPages}`}</span> */}
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className={`px-3 py-1 rounded cursor-pointer ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {/* Next */}
                            <GrFormNextLink size={24} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}