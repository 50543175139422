import React from 'react'
import { Link } from 'react-router-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { config } from '../Config'

export default function NotFound({
  extraClass
}
) {
  return (
    <div className={`m-auto w-[1024px] p-10 smsize:w-full ${extraClass}`}>
      <HelmetProvider>
        <Helmet>
          <title>Page Not Found | {config.appName}</title>
          <meta name='description' content='Page Not Found' />
        </Helmet>
      </HelmetProvider>
      <div className='w-full flex flex-col items-center gap-3 text-center'>
        <img src='/404.png.webp' alt='404' />
        <h1 className='text-[50px] font-bold smsize:text-[30px]'>Error! Page Not Found</h1>
        <p className='text-[20px] smsize:text-[16px]'>
          The page you are looking for was moved, removed, renamed or never existed.
        </p>

        <Link to='/' className='common-button text-center'>Return to Home</Link>

      </div>
    </div>
  )
}
