import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
export default function FindTireByVehicle({
    setType
}) {

    return (
        <div className='tire-search flex items-center justify-center'>
            <div className='flex items-center justify-start gap-3 p-3 bg-gray-100 rounded-lg'>
                <Link to={'/search-by-vehicle'}>
                    <img src='/images/rav4.webp' className='w-[200px]' alt='login-side' />
                </Link>
                <div className='flex flex-col gap-3'>
                    <Link to={'/search-by-vehicle'}>
                        <h3 className='font-bold text-[20px]'>Shop tires by vehicle</h3>
                        <span>Enter your make and model to find tires that fit</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
