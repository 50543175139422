import './Home.css'
import React, { Suspense, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import HeroSection from './components/HeroSection'
import CommentSection from './components/CommentSection'
import EasyToBuy from './components/EasyToBuy'
import { useSelector } from 'react-redux'
import ProductSection from '@components/ProductSection'
import { Link } from 'react-router-dom'
import MyImage from '../../components/MyImage'
import { Drawer } from 'antd'
import { usePWAInstall } from 'react-use-pwa-install'
import HeroSectionMobile from './components/HeroSectionMobile'
import SearchByVehicle from './components/SearchByVehicle'
// import { Carousel } from 'antd'

export default function Home() {
  const install = usePWAInstall()
  const [isOpen, setIsOpen] = React.useState(false)
  // console.log('Home.js', install)
  const typeList = useSelector((state) => state.content.productsTypeList) || []
  const isMobile = useSelector((state) => state.content.isMobile)
  const productsTypeList = typeList.filter(
    (item) => item.is_wheel === 0 && item.title !== 'Tire Bags'
  )

  useEffect(() => {
    if (install) setIsOpen(true)
  }, [install])

  const schema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'AutomotiveBusiness',
        name: 'TireMaxx',
        url: 'https://www.tiremaxx.ca',
        logo: 'https://www.tiremaxx.ca/images/logo.webp',
        image: 'https://www.tiremaxx.ca/images/logo.webp',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Bay 12, 91 Skyline Crescent NE',
          addressLocality: 'Calgary',
          addressRegion: 'AB',
          postalCode: 'T2K 5X2',
          addressCountry: 'CA'
        },
        priceRange: '$$',
        telephone: '+1-368-999-9988',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+1-368-999-9988',
          contactType: 'customer service',
          areaServed: 'CA',
          availableLanguage: ['English', 'French']
        },
        sameAs: [
          'https://www.facebook.com/tiremaxxyyc',
          'https://www.instagram.com/tiremaxxyyc',
          'https://twitter.com/tiremaxxyyc',
          'https://www.tiktok.com/@tiremaxxyyc'
        ]
      },
      {
        '@type': 'LocalBusiness',
        name: 'Tiremaxx Tire Shop in Calgary',
        image: 'https://www.tiremaxx.ca/images/logo.webp',
        telephone: '+1-368-999-9988',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Bay 12, 91 Skyline Crescent NE',
          addressLocality: 'Calgary',
          addressRegion: 'AB',
          postalCode: 'T2K 5X2',
          addressCountry: 'CA'
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: 51.097778,
          longitude: -114.045
        },
        openingHours: 'Mo-Fr 09:00-17:00, Sa 10:00-15:00',
        url: 'https://www.tiremaxx.ca',
        priceRange: '$$'
      }
    ]
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta
            property="og:title"
            content={'TireMaxx - Your Trusted Tire Shop in Canada'}
          />
          <meta
            property="og:description"
            content={
              'Find the best tires for your car, truck, or SUV at TireMaxx. Affordable prices and fast shipping.'
            }
          />
          <meta
            property="og:image"
            content={'https://tiremaxx.ca/images/hero5.webp'}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content={'website'} />
          <meta property="og:locale" content="en_CA" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={'TireMaxx - Your Trusted Tire Shop in Canada'}
          />
          <meta
            name="twitter:description"
            content={
              'Find the best tires for your car, truck, or SUV at TireMaxx. Affordable prices and fast shipping.'
            }
          />
          <meta
            name="twitter:image"
            content={'https://tiremaxx.ca/images/hero5.webp'}
          />
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
      </HelmetProvider>
      {/* {install && <button onClick={install}>Install Our App</button>} */}
      <Drawer
        title="Install Our App"
        placement="bottom"
        closable={true}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        height={150}
        forceRender={true}
      >
        <div className="install-app">
          {install && (
            <button onClick={install} className="common-button">
              Install our app
            </button>
          )}
        </div>
      </Drawer>
      {/* <PWAPrompt
                // copyClosePrompt="Close"
                copyDescription="Best Deals On Tires | Calgary Tires | Calgary Tire Shop | Calgary Tire Sales | Calgary Tire Stores | Calgary Used Tires | Best Tire Shop Calgary | Airdrie Tires | Airdrie Tire Shop | Airdrie Tire Sales"
                copyTitle="Tiremaxx"
                appIconPath='/logo192.png'
                timesToShow={3}
                isShown={true}
                copyAddToHomescreenStep={null}
                copyShareStep={null}
            /> */}
      <Suspense fallback={<div>Loading...</div>}>
        {isMobile ? (
          <HeroSectionMobile productsTypeList={productsTypeList} />
        ) : (
          <HeroSection productsTypeList={productsTypeList} />
        )}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <ProductSection productTypeList={productsTypeList} />
      </Suspense>
      {/* <section className='home-seo-section'>
                <div className='home-seo-section-wrapper'>
                    <Carousel dotPosition={'bottom'} autoplay={true} dots={{ className: 'dots-class' }}>
                        <div className='home-seo-section-slide'>
                            <div className='home-seo-section-content'>
                                <div className='home-seo-section-desc'>
                                    <h1>Best Tire Shop Calgary & Airdrie | Tires for Sale Alberta | Edmonton Tire Store</h1>
                                    <h3>Live near Calgary?</h3>
                                    <p><strong>If you're</strong> looking to buy tires in Airdrie or Chestermere, we should be your go-to destination. We are a reputable and reliable platform that offers great deals on high-quality tires for any type of vehicle.
                                        <br /><br />
                                        <strong>One of</strong> the biggest advantages of purchasing tires through Tiremaxx is our extensive selection.
                                        <br /><br />
                                        <strong>Tiremaxx has</strong> a team of experts who are always ready to assist customers with any queries they may have about tire purchases or listings. We provide top-notch customer service, which further enhance the overall experience for both buyers and sellers alike.</p>
                                </div>
                                <div className='home-seo-section-img'>
                                    <img src="/images/Calgary (Small).webp" alt="Calgary" />
                                </div>
                            </div>
                        </div>
                        <div className='home-seo-section-slide'>
                            <div className='home-seo-section-content'>
                                <div className='home-seo-section-desc'>
                                    <h3 style={{ textAlign: 'center' }}>Tiremaxx offers delivery service to Red Deer within 24 hours</h3>
                                    <p>
                                        <img align="left"
                                            className='home-seo-section-reddeer'
                                            width={500} src="https://www.tiremaxx.ca/public/upload/editor/1d494c5c-4596-4d2c-8d17-87137fd933e8.webp" alt="Calgary" />
                                        <strong>Do you</strong> need to purchase tires in Red Deer that are affordable yet accessible? Tiremaxx is an ideal place for locating such a solution! Our vast array of quality options provides a selection suited to both your financial situation and automobile needs.
                                        <br />
                                        <strong>We understand</strong> that buying new tires can be stressful and overwhelming, but with our knowledgeable staff on hand, we make the process as easy as possible. Give us a call and we'll walk you through all of your options so you can make an informed decision without feeling pressured or rushed. We’ll schedule your delivery and you’ll have your new set of tires within 24 hours.
                                        <br />
                                        <strong>At Tiremaxx</strong>, we pride ourselves on providing excellent customer service and going above and beyond for our customers.
                                        <br /><br />
                                        <strong>So what</strong> are you waiting for? Contact Tiremaxx today and browse our website to find great deals on quality tires!</p>
                                </div>

                            </div>
                        </div>
                    </Carousel>
                </div>
            </section> */}
      <Suspense fallback={<div>Loading...</div>}>
        <section className="home-shipping-section">
          <div className="home-shipping-section-content">
            <h2>
              Alberta Delivery Service Area Starting at $40 for Four Tires.
              Shipping to BC, Manitoba, Saskatchewan available. Please contact
              for a quote.
            </h2>
            <Link to="/services/shipping-area">
              {/* <img width="500" src="/images/shipping_banner.webp" alt="Shipping" /> */}
              <MyImage
                width="500"
                src="/images/shipping_banner.webp"
                alt="Shipping"
              />
            </Link>
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <CommentSection />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <section className="home-dark-section">
          <div className="home-dark-section-content">
            <h2>Tires for Sale in Red Deer, Alberta & Edmonton Wide</h2>
            <Link to="/articles/calgary-food-bank-partnership">
              {/* <img width="500" src="/images/donation.webp" alt="donation" /> */}
              <MyImage width="500" src="/images/donation.webp" alt="donation" />
            </Link>
          </div>
        </section>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <EasyToBuy />
      </Suspense>
    </>
  )
}
