export const config = {
    // The name of the application
    appName: 'Best Tire Shop in Calgary, Airdrie, Edmonton | Used Tires for Sale Alberta',
    phone: '368-999-9988',
    email: 'marketing@tiremaxx.ca',
    open_time: 'Mon-Fri: 09:00-17:00, Sat: 10:00-15:00',
    facebook: 'https://www.facebook.com/tiremaxxyyc/',
    instagram: 'https://www.instagram.com/tiremaxxyyc/',
    twitter: 'https://twitter.com/tiremaxxyyc/',
    tiktok: 'https://www.tiktok.com/@tiremaxxyyc/',
    location: ['Bay 12, 91 Skyline Crescent NE, Calgary, AB T2K 5X2', '4640 Pacific road NE, Calgary'],
    google_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2505.6287538448573!2d-114.04734718412384!3d51.09685854845747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537165f99102ff15%3A0x746a0ea72daff13a!2sBest%20Asia%20Tire%20%3A%20Premium%20Factory%20Direct%20Tires!5e0!3m2!1sen!2sca!4v1666588845375!5m2!1sen!2sca',
    google_map_button: 'https://www.google.com/maps/place/Tiremaxx+Ltd.,+91+Skyline+Crescent+NE,+Calgary,+AB+T2K+5X2/@51.097739,-114.045082,17z/data=!4m6!3m5!1s0x537165f99102ff15:0x746a0ea72daff13a!8m2!3d51.097739!4d-114.045082!16s%2Fg%2F11h0bnprk9',
    google_review: 'https://www.google.com/maps/place/Tiremaxx+Ltd./@51.097739,-114.045082,16z/data=!4m7!3m6!1s0x0:0x746a0ea72daff13a!8m2!3d51.097739!4d-114.045082!9m1!1b1?hl=en',
    // The front base url
    appBaseUrl: process.env.REACT_APP_API_URL || 'http://localhost:4000/api/v1',
    apiUser: process.env.REACT_APP_API_USER || 'tiremaxx',
    apiKey: process.env.REACT_APP_API_KEY || 'ce90b9d7-2422-4e79-9e47-49fe80f9e623',
}