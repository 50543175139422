import './Footer.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { config } from '../Config'
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter
} from 'react-icons/ai'
import { FaTiktok } from 'react-icons/fa'
import Iframe from 'react-iframe'
import { useSelector, useDispatch } from 'react-redux'
import { setNewsFilterTypes } from '../reducers/contentReducer'
import helper from '@utils/helper'
import MyImage from './MyImage'

export default function Footer() {
  const dispatch = useDispatch()
  const newsTypeList = useSelector((state) => state.content.newsTypeList)
  const productsTypeList = useSelector(
    (state) => state.content.productsTypeList
  )

  return (
    <footer>
      <div className="footer-top">
        <div className="footer-top-left">
          <h3>Find our store</h3>
          {/* <p>111</p> */}
          <Iframe
            url={config.google_map}
            width="450px"
            height="300px"
            loading="lazy"
            id=""
            className="google-map"
            display="block"
            position="relative"
          />
        </div>
        <div className="footer-top-right">
          <h3>Let's get social!</h3>
          <p>We're ready to connect with you on your favorite channels.</p>
          <div className="footer-top-right-btns">
            <a href={config.facebook} target="_blank" rel="noopener noreferrer">
              <AiFillFacebook size={48} />
            </a>
            <a
              href={config.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiOutlineInstagram size={48} />
            </a>
            <a href={config.twitter} target="_blank" rel="noopener noreferrer">
              <AiOutlineTwitter size={48} />
            </a>
            <a href={config.tiktok} target="_blank" rel="noopener noreferrer">
              <FaTiktok size={36} />
            </a>
          </div>
          <h3>Contact Us</h3>
          <p>
            <label>Location:</label>
            {config.location[0]}
          </p>
          <div className="footer-top-right-contact">
            <p>
              <label>Phone:</label>
              <a href={`tel:${config.phone}`}>{config.phone}</a>
            </p>
            <p>
              <label>Email:</label>
              <a href={`mailto:${config.email}`}>{config.email}</a>
            </p>
          </div>
          <a
            href="https://www.drivercapital.ca/maxloan/?key=YOURKEY"
            target="_blank"
            rel="noindex nofollow noopener noreferrer"
          >
            {/* <img width={'100%'} src="/images/driver-capital/DC_Banner_TiresRimsLifts.webp" alt='Financing Available' /> */}
            <MyImage
              src="/images/driver-capital/DC_Banner_TiresRimsLifts.webp"
              alt="Financing Available"
            />
          </a>
        </div>
      </div>
      <div className="footer-content">
        <div className="footer-content-link">
          <div className="footer-link">
            <h3>Tires</h3>
            {productsTypeList &&
              productsTypeList
                .filter((item) => item.is_wheel === 0)
                .map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={`/tires/list/${helper.formatTitleForLink(
                        item.title
                      )}`}
                    >
                      {item.title}
                    </Link>
                  )
                })}
            <Link to="/tires">More Tires</Link>
          </div>
          <div className="footer-link">
            <h3>Wheels</h3>
            {productsTypeList &&
              productsTypeList
                .filter((item) => item.is_wheel === 1)
                .map((item, index) => {
                  return (
                    <Link key={index} to="/wheels">
                      {item.title}
                    </Link>
                  )
                })}
            <Link to="/wheel-balance">Wheel Balance Weights</Link>
            {/* <Link to="/wheels">More Wheels</Link> */}
            <h3>Articles</h3>
            {newsTypeList &&
              newsTypeList.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to="/articles"
                    onClick={() => {
                      dispatch(
                        setNewsFilterTypes({ type: item.id, action: 'set' })
                      )
                    }}
                  >
                    {item.title}
                  </Link>
                )
              })}
            <Link to="/articles">More Articles</Link>
          </div>
        </div>
        <div className="footer-content-link">
          <div className="footer-link">
            {/* <h3>Policies</h3>
                        <Link to="/policy/refund">Refund Policy</Link>
                        <Link to="/policy/store">Warranty Policy</Link>
                        <Link to="/policy/privacy">Privacy Policy</Link>
                        <Link to="/policy/pricing">Exclusive Pricing Policy</Link> */}
            <h3>Services</h3>
            <Link to="/services/tire-change">Tire Installation</Link>
            <Link to="/services/tire-rims">Rims and Wheels</Link>
            <Link to="/services/financing">Financing</Link>
            <Link to="/services/shipping">Local Shipping</Link>
            <Link to="/services/tire-storage">Tire Storage</Link>
            <Link to="/services/shipping">Shipment to Edmonton & RedDeer</Link>
            <h3>Business</h3>
            <Link to="/login">Business Login</Link>
            <Link to="/policy">Store Policies</Link>
          </div>
          <div className="footer-link">
            <h3>About Us</h3>
            <Link to="/about">About</Link>
            <Link to="/partner-brands">Partner Brands</Link>
            <Link to="/contact">Contact</Link>
            <h3>FAQ</h3>
            <Link to="/faq">Frequently Asked Questions</Link>
            <a
              target="_blank"
              rel="noindex nofollow noopener noreferrer"
              href="https://www.bbb.org/ca/ab/calgary/profile/tire-dealers/best-asia-tire-corp-0017-126683/#sealclick"
            >
              {/* <img className='map' src="/best-asia-tire-bbb-seal.png" alt="Best Asia Tire Corp BBB Business Review" /> */}
              <MyImage
                width={160}
                src="/tiremaxx-seal.png"
                alt="Tiremaxx BBB Business Review"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="copyright-content">
          Copyright © 2017-2025 Tiremaxx. All Rights Reserved.
        </div>
      </div>
    </footer>
  )
}
